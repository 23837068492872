<template>
  <div class="reservationRecord">
    <div class="top">
      <p class="title">预约记录</p>
      <div class="top-right">
        <div class="sx">
          <div class="sx-box">
            <p>预约状态</p>
            <el-select v-model="yyztVal" placeholder="请选择" class="sx-op">
              <el-option
                  v-for="item in yyzt"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div  class="sx-box">
            <p>顾客姓名</p>
            <el-input v-model="valuea" class="sx-op" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="sx">
          <div  class="sx-box">
            <p>预约类型</p>
            <el-select v-model="yylxVal" placeholder="请选择" class="sx-op">
              <el-option
                  v-for="item in yylx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="sx-box">
            <p>医生名</p>
            <el-select class="sx-op" v-model="exid" placeholder="请选择">
              <el-option
                  class="sx-op"
                  v-for="item in restaurants"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div>
        <p style="height: 40px;">截止日期：</p>
        <el-date-picker
            style="width: 250px;margin-right: 10px;"
            v-model="time1"
            format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div  class="sx-box" style="padding-right: 20px;">
        <el-button type="primary" @click="ss">搜索</el-button>
      </div>
    </div>


    <div class="bg">
      <ul class="bts">
        <li>姓名</li>
<!--        <li>性别</li>-->
<!--        <li>年龄</li>-->
        <li>联系方式</li>
        <li>预约编号</li>
        <li>预约时间</li>
        <li>挂号费</li>
        <li>预约类型</li>
        <li>预约状态</li>
        <li>医生/视光师</li>
        <li></li>
        <li></li>
      </ul>
      <div class="bg-box">
        <ul class="content content-s" v-if="datas.length" v-for="(item, idx) in datas" :class="[item.OppointmentState=='rejected'?'xz':'', item.zt=='已过期'?'gq':'']">
          <li style="display: block;">
            <p style="width: 100%;text-align: center;padding: 0;padding-bottom: 5px;">{{item.Customer.CustomerName}}</p>
            <p style="width: 100%;text-align: center;padding: 0;padding-bottom: 5px;">
              <span style="padding-right: 8px">{{item.Customer.CustomerGender=='0'?'女':'男'}}</span>
              {{item.Customer.nn}}
            </p>
          </li>
<!--          <li></li>-->
<!--          <li></li>-->
          <li>{{item.Customer.CustomerTel}}</li>
          <li>{{item.OppointmentId.slice(0,8).toUpperCase()}}</li>
          <li>{{item.OppointmentTime}}</li>
          <li>{{item.Schedule.ScheduleCost}}元</li>
          <li>{{item.OppointmentType}}</li>
          <li>{{item.zt}}</li>
          <li>{{item.Expert?item.Expert.ExpertName:'无'}}</li>
          <li><el-button v-if="item.OppointmentState !== 'rejected' && item.OppointmentState !== 'done'" type="primary" @click="updata(item)">修改</el-button></li>
          <li><el-button @click="gojc(item.OppointmentTargetChooseId, item.OppointmentId, item.Customer)" v-if="item.OppointmentState !== 'rejected' && item.OppointmentState !== 'done'" type="primary">接诊</el-button></li>
        </ul>
      </div>
    </div>


    <el-dialog
        title="修改预约信息"
        :visible.sync="xg"
        width="50%"
        >
      <p class="pad-10">预约时间</p>
      <el-date-picker
          type="datetime"
          v-model="times"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          align="center"
          :picker-options="pickerOptions">
      </el-date-picker>
      <p class="pad-40">备注</p>
      <el-input
          class="notosr"
          type="textarea"
          size="mini"
          resize="none"
          :autosize="{ minRows: 4, maxRows: 8}"
          placeholder="请输入内容"
          v-model="note">
      </el-input>
      <span slot="footer" class="dialog-footer upnote">
        <el-button @click="xg = false">返 回</el-button>
        <el-button @click="save('rejected')" class="mar-20" type="danger">取消此预约</el-button>
        <el-button type="primary" @click="save('')">保 存</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="请选择："
        :visible.sync="dialogVisible"
        width="30%"
        >
<!--      <p style="padding-bottom: 10px"><el-button type="primary" @click="save('rejected')">取消预约</el-button></p>-->
      <p style="padding-bottom: 10px">      <el-button  style="width: 9em;" type="primary" @click="$router.push({path: 'reservationRecord/againReserve', query: {csid: updatap.Customer.CustomerId, opid: updatap.Customer.CustomerWeiXinOpenId}})">重新预约</el-button></p>
      <p style="padding-bottom: 10px" @click="save('rejected')"><el-button style="width: 9em;" type="danger">取消预约</el-button></p>
    </el-dialog>
  </div>
</template>

<script>
import pb from '@/publicFn/baseFn'
import axios from "@/api/axios"
export default {
  name: "reservationRecord",
  data() {
    return {
      datas: [],
      exid: '',
      time1: [],
      restaurants: [],
      dialogVisible: false,
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      times: '',
      note: '',
      yyzt: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'orde',
          label: '未支付'
        },
        {
          value: 'rejected',
          label: '已取消'
        },
        {
          value: 'expected',
          label: '候诊'
        },
        {
          value: 'done',
          label: '已就诊'
        },
        {
          value: 'wait',
          label: '已报到'
        },
        {
          value: "waitingpay",
          label: "等待付款"
        },
        {
          value: 'called',
          label: '已叫号'
        }
      ],
      yyztVal: '',
      yylx: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '初查',
          label: '初查'
        },
        {
          value: '常规检查',
          label: '常规检查'
        },
        {
          value: '更换镜片',
          label: '更换镜片'
        },
        {
          value: '复查',
          label: '复查'
        },
        {
          value: '学片',
          label: '学片'
        },
        {
          value: '视觉训练',
          label: '视觉训练'
        },
        {
          value: '其他检查',
          label: '其他检查'
        }
      ],
      yylxVal: '',

      options: [],
      valuea: '',
      updatap: {},

      xg: false
    }
  },
  created() {
    this.time1 = [pb.getTime(-10), pb.getTime(10)]
    // console.log(this.time1)
    this.$store.commit('isShow/upBottom', false)
     this.inits()

    this._api.userCurdApi.getEmployeesList()
        .then(res => {
          if (res.GetListResult && res.GetListResult.length>0) {
            this.restaurants = res.GetListResult.map(item => {
              return {
                label: `${item.ExpertName}`,
                value: `${item.ExpertId}`
              }
            })
            this.restaurants.push({
              label: ``,
              value: ``
            })
            // this.restaurants = this.loadAll();
          }
        })

    this._api.Company.getYyType()
    .then(res => {
      let arr = [
        {
          value: '',
          label: '全部'
        }
      ]
      if (res.GetResult && res.GetResult.length) {
        for (let i = 0; i < res.GetResult.length; i++) {
          arr.push({
            value: res.GetResult[i],
            label: res.GetResult[i]
          })
        }

        this.yylx = [...arr]
        arr = null
      }
    })
  },
  methods: {
    state(e) {
      // console.log(e)
    },
    updata(item) {
      this.dialogVisible = !this.dialogVisible
      this.updatap = item
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    ss() {
      let yyztv = this.yyztVal,
          time = [...this.time1]
      if (this.yyztVal === ' ') {
        yyztv = ''
        time = [pb.getTime(-10), pb.getTime(-1)]
      }
      this._api.Company.getCustomerAppointmentList(yyztv, this.yylxVal, this.valuea, this.exid, time)
          .then(res => {
            if (res.GetListResult && res.GetListResult.length) {
              // console.log(res)
              for (let i = 0; i < res.GetListResult.length; i++) {
                // if (!res.GetListResult[i].Schedule) {
                //   res.GetListResult[i].Schedule = {
                //     ScheduleCost: 0,
                //     ScheduleType: '初查'
                //   }
                // }
                if (!res.GetListResult[i].Schedule) {
                  res.GetListResult[i].Schedule = {
                    ScheduleCost: 0,
                    ScheduleType: ''
                  }
                }
                if (res.GetListResult[i].Customer) {
                  res.GetListResult[i].Customer.nn = pb.getYear(res.GetListResult[i].Customer.CustomerBirthday)
                }else {
                  res.GetListResult[i].Customer = {}
                }
                switch (res.GetListResult[i].OppointmentState) {
                  case "rejected":
                    res.GetListResult[i].zt = '已取消'
                    break
                  case "expected":
                    let date = new Date(res.GetListResult[i].OppointmentTime)
                    if (date.getTime() < Date.now()) {
                      res.GetListResult[i].zt = '已过期'
                    }else {
                      res.GetListResult[i].zt = '待接诊'
                    }
                    break
                  case "done":
                    // console.log('done')
                    res.GetListResult[i].zt = '已就诊'
                    break
                  case "called":
                    res.GetListResult[i].zt = '已叫号'
                    break
                  case "order":
                    // console.log(2222222222222)
                    res.GetListResult[i].zt = '未支付'
                    break
                  default:
                    res.GetListResult[i].zt = ''
                }
              }
              this.datas = [...res.GetListResult]

            }else {
              this.datas = []
            }
          })
    },

    save(zt) {
      let datas = JSON.parse(JSON.stringify(this.updatap))
      if (!zt) {
        datas.OppointmentTime = this.times
        datas.OppointmentRemarks = this.note
      }
      delete datas.Company
      delete datas.Customer
      delete datas.zt
      delete datas.Shop
      delete datas.Expert

      if (zt) {
        datas.OppointmentState = zt
        // console.log(zt)
      }


      axios.post(pb.getUrl('UpdateOppointment'), {
        token: {
          sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
          TimeSpan: '1618198660'
        },
        o: datas
      })
      .then(res => {
        if (res.UpdateResult) {
          this.dialogVisible = false
          this.xg = false
          this.inits()
        }else {
          this.$alert('失败，请稍后再试', '提示')
        }
      })
    },

    inits() {
      this._api.Company.getCustomerAppointmentList()
          .then(res => {
            if (res.GetListResult && res.GetListResult.length) {
              // console.log(res)
              for (let i = 0; i < res.GetListResult.length; i++) {
                if (!res.GetListResult[i].Schedule) {
                  // console.log(12222)
                  res.GetListResult[i].Schedule = {
                    ScheduleCost: 0,
                    ScheduleType: ''
                  }
                }
                if (res.GetListResult[i].Customer) {
                  res.GetListResult[i].Customer.nn = pb.getYear(res.GetListResult[i].Customer.CustomerBirthday)
                }else {
                  res.GetListResult[i].Customer = {}
                }
                // switch (res.GetListResult[i].OppointmentState) {
                //   case "rejected":
                //     res.GetListResult[i].zt = '已取消'
                //     break
                //   case "expected":
                //     let date = new Date(res.GetListResult[i].OppointmentTime)
                //     if (date.getTime() < Date.now()) {
                //       res.GetListResult[i].zt = '候诊'
                //     }else {
                //       res.GetListResult[i].zt = '待接诊'
                //     }
                //     break
                //   case "done":
                //     res.GetListResult[i].zt = '已就诊'
                //     break
                //   case "called":
                //     res.GetListResult[i].zt = '叫号'
                //     break
                //   case "order":
                //     // console.log(2222222222222)
                //     res.GetListResult[i].zt = '未付款'
                //     break
                //   default:
                //     res.GetListResult[i].zt = ''
                // }
                switch (res.GetListResult[i].OppointmentState) {
                  case "rejected":
                    res.GetListResult[i].zt = '已取消'
                    break
                  case "expected":
                    let date = new Date(res.GetListResult[i].OppointmentTime)
                    if (date.getTime() < Date.now()) {
                      res.GetListResult[i].zt = '已过期'
                    }else {
                      res.GetListResult[i].zt = '待接诊'
                    }
                    break
                  case "done":
                    // console.log('done')
                    res.GetListResult[i].zt = '已就诊'
                    break
                  case "called":
                    res.GetListResult[i].zt = '已叫号'
                    break
                  case "order":
                    // console.log(2222222222222)
                    res.GetListResult[i].zt = '未支付'
                    break
                  default:
                    res.GetListResult[i].zt = ''
                }
              }
              this.datas = [...res.GetListResult]
            }
          })
    },

    xgyyxx() {
      this.dialogVisible = false
      this.xg = true
    },

    gojc(id, opid, data) {
      if (id == "00000000-0000-0000-0000-000000000000" || !id) {
        this._api.physicianVisits.addNewChoose(id, opid)
            .then(res => {
              if (res.msg != 'Success') {
                this.$alert(res.msg, '提示')
                return
              }
              if (res.InsertResult) {
                // console.log(res)
                pb.clCsData()
                this.sx = true

                let nian = pb.getYear(data.CustomerBirthday)
                this.$store.commit('users/upLogged', true)   //更新登陆状态
                this.$store.commit('users/upCsUserItem', '')
                this.$store.commit('users/upCsAge', nian)
                this.$store.commit('users/UpCsUser', data)
                this.$store.commit('physicianVisits/upCancellation', false)
                this.$emit('isLing', false)

                this.$store.commit('physicianVisits/upXzConsulting',  res.InsertResult.Choose.ChooseId)
                this.$store.commit('physicianVisits/upJump', 1)
                this.$router.push({path: '/'})
              }
            })
      }else {
        pb.clCsData()

        this.sx = true

        let nian = pb.getYear(data.CustomerBirthday)
        this.$store.commit('users/upLogged', true)   //更新登陆状态
        this.$store.commit('users/upCsUserItem', '')
        this.$store.commit('users/upCsAge', nian)
        this.$store.commit('users/UpCsUser', data)
        this.$store.commit('physicianVisits/upCancellation', false)
        this.$emit('isLing', false)


        this.$store.commit('physicianVisits/upXzConsulting',  id)
        this.$store.commit('physicianVisits/upJump', 1)
        this.$router.push({path: '/'})
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .reservationRecord {
    width: 100vw;
    border-top: 1px solid #000000;
  }
  .top {
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: .2rem 0;
    .sx {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: .2rem;
      .sx-box {
        padding-right: .3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {padding-right: .1rem;}
        .sx-op {
          width: 10vw;
          cursor: pointer;
        }
      }
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      padding-right: 2rem;
    }
  }
  .el-dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
  }
  .el-dropdown-menu {
    background: #ece9e9;
    text-align: center;
    li {
      border-bottom: 1px solid #000000;
      padding: 0 .4rem;
      margin: 0 .1rem;
    }
  }
  .bg {
    width: 100vw;
    .bg-box {
      width: 100%;
      height: 55vh;
      overflow-y: auto;
      .content-s:hover {
        background: #efefef;
      }
    }
    .bg-box::-webkit-scrollbar { width: 0 !important }
    ul {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        width: 11%;
      }
    }
    .bts {
      background: rgba(0,0,0,0.8);
      color: #ffffff;
      padding: 10px 0;
    }
    .content {
      li {
        padding: .2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          padding: 3px 10px;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }

  .upnote {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pad-10 {padding-bottom: 10px;}
  .pad-40 {padding-top: 40px; padding-bottom: 10px;}
  .notosr {width: 400px;}
  .mar-20 {margin: 0 65px;}
  .xz {color: #888888}
  .gq {
    color: #ff0101;
  }
</style>
